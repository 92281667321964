/**
 * Store for BEND relates vuex
 */

// import axios from 'axios'
import Vue from 'vue'
export default {
  // https://www.drupal.org/project/jsonapi
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */

  state: {
    calls: {
      organization: false,
      projects: false,
      webuser: false,
    },
    onlineProjects: [],
    // TODO move to pack.status
    // udaPackStatus: { udaLocation: 'noData', udaProgressPie: 'noData' },
    // selectedProject: [],
    /*
    selectedProject: [
      { name: 'Select Project',
      packs: { udacprogpie: { data: [] },
    } } ],
    */
    // name: 'SelectP',
    organization: { allStaff: [], currentStaff: [{ roles: [] }], projects: [] },
    activeForm: {},
  },

  /* =================================================
  //                     MUTATIONS
  ================================================== */

  mutations: {
    // Reset to initial state
    reset (state) {
      state.calls.organization = false
      state.calls.projects = false
      state.calls.webuser = false
      state.organization = { allStaff: [], currentStaff: [] }
    },

    // project list from bend
    setOnlineProjects (state, payload) {
      // Vue.set(onlineProjects, 'name', payload.name)
      state.onlineProjects = { ...state.onlineProjects, name: payload.name }
      state.onlineProjects = payload
    },

    // setSelectedProject
    setSelectedProject (state, payload) {
      state.selectedProject = payload
      state.name = payload[0].name
    },

    // Packages and arguments
    setPackageArguments (state, prop) {
      state.packageArguments = prop
    },

    // Packages and arguments as named ojbect
    // TODO Remove setPackageArguments mutation, action... and merge it with this
    setPackArguments (state, prop) {
      state.packArguments = prop
    },

    // called from ocpu action [getPackage]
    setPackageData (state, payload) {
      // console.log('mutating ' + payload.pack)
      const sppack = state.selectedProject[0].packs[payload.pack]
      const oppack = state.onlineProjects[payload.pkey].packs[payload.pack]
      // console.log(state.name)
      // console.log(state.selectedProject[0].packs[payload.packname])
      Vue.set(sppack, 'data', payload.value)
      Vue.set(oppack, 'data', payload.value)
    },

    // Updates Sync data in Packages of onlineProjects and selectedProject
    setDsSyncData (state, payload) {
      const pack = 'udac' + payload.dsname
      // console.log(pack)
      if (payload.updateSelected) {
        state.selectedProject[0].packs[pack].syncLink = payload.link
        state.selectedProject[0].packs[pack].syncDate = payload.date
      }
      state.onlineProjects[payload.key].packs[pack].syncLink = payload.link
      state.onlineProjects[payload.key].packs[pack].syncDate = payload.date
    },

    // Updates Project sync data in onlineProjects and selectedProject
    setProjectSyncData (state, payload) {
      if (payload.updateSelected) {
        state.selectedProject[0].syncDate = payload.date
        state.selectedProject[0].submissions = payload.submission
      }
      state.onlineProjects[payload.key].syncDate = payload.date
      state.onlineProjects[payload.key].submissions = payload.submission
    },

    setFormSyncData (state, payload) {
      const f = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      f.syncDate = payload.date
      f.submissions = payload.submission
    },

    setSyncMode (state, payload) {
      const sppack = state.selectedProject[0]
      const oppack = state.onlineProjects[payload.pkey]
      Vue.set(sppack, 'syncMode', payload.mode)
      Vue.set(oppack, 'syncMode', payload.mode)
    },

    // Update bend calls. Change to true if the call is done.
    setCalls (state, payload) {
      state.calls = Object.assign({}, state.calls, payload)
    },

    // Organization from bend
    setOrganization (state, payload) {
      // Called from AccountSettings, getOrganization()
      // console.log(payload)
      state.organization = payload
    },

    // Organization from bend
    delOrganization (state, payload) {
      // Called from organization.js, delOrganization()
      for (let i = 0; i < payload.length; i++) {
        delete state.organization[payload[i]]
      }
    },

    // Organization from bend
    setOrgNewProject (state, payload) {
      // Called from FormDraft, addNewForm()
      state.organization.newproject = payload
    },

    // Set organization's projects
    setOrgProjects (state, payload) {
      // Called from FormDraft, addNewForm()
      state.organization.projects = payload
    },

    // Set organization's projects
    setOrgStaff (state, payload) {
      // Called from FormDraft, addNewForm()
      state.organization.staff = payload
    },

    // Set form of Selected Project
    setProjectForms (state, payload) {
      // Called from FormDraft, addNewForm()
      // console.log(payload.pindex)
      state.organization.projects[payload.pindex].forms = payload.forms
    },

    // Set form of Selected Project
    setOrganizationProjects (state, payload) {
      // Called from projectSettings, deleteProject()
      state.organization.projects = payload.projects
    },

    // Set form of Selected Project
    setProjectRoles (state, payload) {
      // Called from projectSettings, deleteProject()
      state.organization[payload.ix.pindex].roles = payload.roles
    },

    // Adds value into organization, called from mixin webusers.js
    addToOrganization (state, payload) {
      state.organization = Object.assign({}, state.organization, payload)
    },

    // Update user type, called from AccountSettings
    updateUserType (state, user) {
      // console.log(user)
      state.organization.allStaff[user.index].type = user.type
    },

    // Update Experimental status e.g. tester = prop
    updateTesterState (state, prop) {
      // console.log(user)
      state.organization.tester = prop
    },

    // Updates ODK Form data in onlineProjects and selectedProject
    saveOdkForm: (state, payload) => {
      // console.log(payload)
      const p = state.selectedProject[0]
      const key = p.key
      // update selectedProject
      p.odkmodels = payload[0]
      p.odkquestions = payload[1]
      p.odklanguages = payload[2]
      p.odksettings = payload[3]
      // update in onlineProjects
      state.onlineProjects[key].odkmodels = payload[0]
      state.onlineProjects[key].odkquestions = payload[1]
      state.onlineProjects[key].odklanguages = payload[2]
      state.onlineProjects[key].odksettings = payload[3]
    },

    // Updates ODK Form data in onlineProjects and selectedProject
    updateOdkForm: (state, payload) => {
      const form = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      form.odkquestions = payload.questions
      form.odkmodels = payload.models
      form.odklangs = payload.languages
      form.odksettings = payload.formsettings
    },

    // Updates ODK Form settings
    updateOdkSettings: (state, payload) => {
      // console.log(payload)
      const form = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      form.odksettings = payload.settings
    },

    updateOdkFormPhase: (state, payload) => {
      // console.log(payload)
      const form = state.organization.projects[payload.ix.pindex].forms[payload.ix.findex]
      form.odksettings.phase = payload.phase
      if ('attachment' in payload) {
        form.odksettings.attachment = payload.attachment
      }
      if ('enketoid' in payload) {
        form.odksettings.enketoid = payload.enketoid
      }
    },

    // Updates Data Filters data in onlineProjects and selectedProject
    saveDataFilter: (state, payload) => {
      const p = state.selectedProject[0]
      const key = p.key
      // update selectedProject
      p.datafilter = payload
      // update in onlineProjects
      state.onlineProjects[key].datafilter = payload
    },

    // /////////////////////////////////////////////////

    // ALL PROJECTS Related

    // SELECTED PROJECT Related
    selectedProject (state, projectList) {
      state.selectedProject = projectList
    },

    setProjectListPackage (state, [projectPackage, key]) {
      state.projectList[key].udaPackages = projectPackage
    },

    // setPackages (state, packageList) {
    //   state.packageList = packageList
    // },

    setProjectPackage (state, projectPackage) {
      state.selectedProject[0].udaPackages = projectPackage
    },

    udaPackStatus (state, [packageName, status]) {
      // console.log('udaPackStatus ga keldi')
      // console.log(packageName)
      state.udaPackStatus[[packageName]] = status
    },
  },

  /* =================================================
  //                     GETTERS
  ================================================== */
  getters: {
    // Check and change I'm using .packs instead of .udsPacks
    getPackByName: state => packName => {
      return state.selectedProject.udaPacks.find(
        pack => pack.name === packName,
      )
    },

    // Check and change I'm using .packs instead of .udsPacks
    getsyncDate: state => packName => {
      return state.selectedProject.packs[packName].syncDate
    },
  },

  /* =================================================
  //                     ACTIONS
  ================================================== */
  actions: {
    // Reset state when loged out
    reset ({ commit }) {
      commit('reset')
    },

    // Get all online projects from bend and
    // commit setOnlineProjects; setSelectedProject
    // Called with async key from [initialSetup]
    getOnlineProjects ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        // console.log('getOnlineProjects da')
        this._vm.$httpBend
          .get(
            '/jsonapi/node/projects?include=field_uda_packages&sort=-field_dates.value',
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            },
          )
          .then(response => {
            const mainPart = response.data.data
            const packagePart = response.data.included
            let incStart = 0
            let incLen = 0
            let incStop = 0
            const projectList = []
            for (let k = 0, len = mainPart.length; k < len; k++) {
              const p = {}
              p.key = k
              p.id = mainPart[k].id
              p.nid = mainPart[k].attributes.drupal_internal__nid
              p.name = mainPart[k].attributes.title
              p.body = mainPart[k].attributes.body.value
              p.startDate = mainPart[k].attributes.field_dates.value
              p.syncDate = mainPart[k].attributes.field_date
              p.formid = mainPart[k].attributes.field_form_id
              p.server = mainPart[k].attributes.field_url.uri
              p.pid = mainPart[k].attributes.field_project_id
              p.totalsurvey = mainPart[k].attributes.field_total_survey
              p.days = mainPart[k].attributes.field_integer
              p.sumcolumn = mainPart[k].attributes.field_text
              p.sumvalue = mainPart[k].attributes.field_value
              p.lang = mainPart[k].attributes.field_lang
              p.submissions = mainPart[k].attributes.field_submissions
              p.fdatalink = ''
              p.odkmodels = JSON.parse(
                mainPart[k].attributes.field_odkmodels,
              )
              p.odkquestions = JSON.parse(
                mainPart[k].attributes.field_odkquestions,
              )
              p.odklangs = JSON.parse(
                mainPart[k].attributes.field_odklangs,
              )
              p.odksettings = JSON.parse(
                mainPart[k].attributes.field_odksettings,
              )

              // p['syncMode'] = '600000'

              const objPacks = []
              const pack = {}
              // Get all packages included to this project
              incLen = mainPart[k].relationships.field_uda_packages.data.length
              incStop = incLen + incStop
              incStart = incStop - incLen
              for (let i = incStart; i < incStop; i++) {
                const oi = {}
                let packName = ''
                oi.id = packagePart[i].id
                oi.nid = packagePart[i].attributes.drupal_internal__nid
                oi.name = packagePart[i].attributes.title
                oi.syncDate = packagePart[i].attributes.field_date
                oi.syncLink = packagePart[i].attributes.field_link
                packName = packagePart[i].attributes.title

                objPacks.push(oi)
                pack[packName] = oi
              }
              p.udaPacks = Object.assign([], p, objPacks)
              p.packs = Object.assign({}, pack)
              projectList.push(p)
            }
            commit('setOnlineProjects', projectList)
            commit('setSelectedProject', [projectList[0]])
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Gets all term from Package Name taxonomy with arguments
    // Called with async key from [initialSetup]
    argumentsGetTaxonomy ({ rootState, commit }) {
      // console.log('argumentsGetTaxonomy da')
      // {server}/jsonapi/taxonomy_term/package_names?fields[taxonomy_term--package_names]=name,field_arguments,description
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .get(
            'jsonapi/taxonomy_term/package_names?fields[taxonomy_term--package_names]=name,field_arguments,description',
            {
              headers: {
                Authorization: `Bearer ${rootState.auth.token}`,
              },
            },
          )
          .then(response => {
            // console.log(response)
            const myResponse = response.data.data
            const taxonomyobj = {}
            const taxonomy = []
            for (var i = 0, len = myResponse.length; i < len; i++) {
              const obj = {}
              let tName = ''
              obj.name = myResponse[i].attributes.name
              obj.id = myResponse[i].id
              obj.description = myResponse[i].attributes.description.value
              obj.arguments = myResponse[i].attributes.field_arguments
              tName = myResponse[i].attributes.name

              taxonomyobj[tName] = obj
              taxonomy.push(obj)
            }
            // console.log(taxonomy)
            commit('setPackageArguments', taxonomy)
            commit('setPackArguments', taxonomyobj)
            // console.log("argumentsGetTaxonomy bitti");
            resolve(response)
          })
          .catch(error => {
            // console.log('argumentsGetTaxonomy error')
            reject(error)
          })
      })
    },

    projectSelected ({ commit, dispatch }, nextProject) {
      // console.log('In projectSelected')
      const pkey = nextProject[0].key
      return dispatch(
        { type: 'ocpu/runOcpuCode', pkey: pkey },
        { root: true },
      ).then(() => {
        commit('setSelectedProject', nextProject)
        const formid = nextProject[0].formid
        // console.log(formid)
        // dispatch('ocpu/getTableList', formid, { root: true })
        dispatch('ocpu/getTableListRules', formid, { root: true })
      })

      /* if (state.selectedProject[0].udaPacks.length !== 0) {
        // console.log('udaPacks.length !== 0')
        dispatch('udaPack', selectedProject)
      } else {
        // console.log('udaPacks.length !== 0 else')
        return dispatch('getPackageList', selectedProject[0].nid).then(() => {
          dispatch('udaPack', selectedProject)
        })
      }  */
    },

    // Write dataset sync data to  Bend's Package Details
    // Called from ocpu after success action [genDataset]
    // OLD CODE
    setPackSyncData ({ dispatch, commit, rootState }, props) {
      // console.log('setPackSyncData da')
      const p = rootState.bend.selectedProject[0]
      const key = p.key
      const d = new Date()
      const syncDate = d.toISOString().slice(0, 19) + '+00:00'
      const id = props[0]
      const link = props[1]
      const dsname = props[2]
      const date = syncDate
      commit('setDsSyncData', {
        key: key,
        link: link,
        date: syncDate,
        dsname: dsname,
        updateSelected: true,
      })
      // console.log(" here in syncData " + key);

      // console.log(id)
      // console.log(link)
      // console.log(date)
      const patchBody = {
        data: {
          type: 'node--package_details',
          id: `${id}`,
          attributes: { field_link: `${link}`, field_date: `${date}` },
        },
      }
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .patch('/jsonapi/node/package_details/' + id, patchBody, {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            // console.log('setPackSyncData error')
            // console.log(error)
            reject(error)
          })
      })
    },

    // // Write dataset sync data to Project node
    // // Called from ocpu after success action [udacUpdate]
    // // OLD CODE
    // setProjectSyncData ({ dispatch, commit, rootState }, props) {
    //   // console.log('setProjectSyncData da')
    //   // console.log(" here in syncData " + projectKey);
    //   const id = props[0]
    //   const key = props[1]
    //   const date = props[2]
    //   const sserver = props[3]
    //   // console.log(id)
    //   // console.log(date)
    //   const patchBody = {
    //     data: {
    //       type: 'node--projects',
    //       id: `${id}`,
    //       attributes: {
    //         field_date: `${date}`,
    //         field_submissions: `${sserver}`,
    //       },
    //     },
    //   }
    //   return new Promise((resolve, reject) => {
    //     this._vm.$httpBend
    //       .patch('/jsonapi/node/projects/' + id, patchBody, {
    //         headers: {
    //           Authorization: `Bearer ${rootState.auth.token}`,
    //           'Content-Type': 'application/vnd.api+json',
    //           Accept: 'application/vnd.api+json',
    //         },
    //       })
    //       .then(response => {
    //         // console.log(response)
    //         commit('setProjectSyncData', {
    //           key: key,
    //           date: date,
    //           submission: sserver,
    //         })
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         // console.log('setPackSyncData error')
    //         // console.log(error)
    //         reject(error)
    //       })
    //   })

    //   /*
    //   return dispatch('udacUpdate', [projectKey, updateSelected]).then(() => {
    //     const p = state.projectList[projectKey]
    //     let projectNid = p.nid
    //     let syncDate = p.sync.date
    //     let syncFolder = p.sync.folder
    //     const postParams = {
    //       _links: {
    //         type: {
    //           href: 'https://bend.udacapi.org/rest/type/node/projects',
    //         },
    //       },
    //       type: [{ target_id: 'projects' }],
    //       field_date: [{ value: syncDate }],
    //       field_sync_folder: [{ value: syncFolder }],
    //       field_submissions: [{ value: sserver }],
    //     }
    //    // console.log(postParams)
    //     return new Promise((resolve, reject) => {
    //       this._vm.$httpBend
    //         .patch('/node/' + projectNid + '?_format=json', postParams, {
    //           headers: {
    //             Authorization: `Bearer ${state.auth.token}`,
    //             // 'Content-Type': 'application/hal+json',  // Delete me later
    //             // Accept: 'application/hal+json',  // Delete me later
    //           },
    //         })
    //         .then(response => {
    //           commit('setProjectSyncDate', [
    //             projectKey,
    //             syncDate,
    //             updateSelected,
    //           ])
    //           resolve(response)
    //         })
    //         .catch(err => {
    //         // console.log('actA error')
    //           reject(err)
    //         })
    //     })
    //   })
    //   */
    // },

    // Write dataset sync data to Project node
    // Called from Active Forms page after success action [udacUpdate]
    setFormSyncData ({ commit, rootState, state }, props) {
      // console.log(" here in syncData " + projectKey);
      const ocpuPack = rootState.ocpu.ocpuPack
      const id = props[0]
      const key = props[1]
      const date = props[2]
      const sserver = props[3]
      const patchBody = {
        data: {
          type: 'node--form',
          id: `${id}`,
          attributes: {
            field_date: `${date}`,
            field_submissions: `${sserver}`,
          },
        },
      }
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${id}`,
            patchBody: patchBody,
            orgid: state.organization.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              commit('setFormSyncData', {
                ix: key,
                date: date,
                submission: sserver,
              })
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // // /////////////////////////////////////////////////////////////////////////////
    // // TODO later delete surveyId, survey and choice from projectList.push({
    //   // TOFIX should not use baseLinkOcpu use instead ocpu/ocpuUrl
    // async getProjectList ({ commit, dispatch, state }) {
    //   // console.log('getProjectList da')
    //   await dispatch('auth/getToken', { root: true })
    //   return new Promise((resolve, reject) => {
    //     this._vm.$httpBend
    //       .get('/api/projects-central', {
    //         headers: {
    //           Authorization: `Bearer ${state.auth.token}`,
    //         },
    //       })
    //       .then(response => {
    //         // console.log(response.data)

    //         const projectList = []
    //         for (var i = 0, len = response.data.length; i < len; i++) {
    //           const sync = {}
    //           let link = ''
    //           if (response.data[i].field_sync_folder[0].value.length > 9) {
    //             link =
    //               state.baseLinkOcpu +
    //               '/tmp/' +
    //               response.data[i].field_sync_folder[0].value +
    //               '/files/' +
    //               response.data[i].field_survey_id[0].value
    //           }
    //           sync.date = response.data[i].field_date[0].value
    //           sync.folder = response.data[i].field_sync_folder[0].value
    //           sync.stata = link + '-stata.zip' // clean-up this
    //           sync.spss = link + '-spss.zip' // clean-up this
    //           sync.csv = link + '-csv.zip' // clean-up this
    //           sync.excel = link + '-excel.zip' // clean-up this
    //           projectList.push({
    //             id: response.data[i].uuid[0].value,
    //             nid: response.data[i].nid[0].value,
    //             name: response.data[i].title[0].value,
    //             body: response.data[i].body[0].value,
    //             startDate: response.data[i].field_dates[0].value,
    //             formId: response.data[i].field_form_id[0].value,
    //             surveyId: response.data[i].field_survey_id[0].value,
    //             pId: response.data[i].field_project_id[0].value,
    //             totalSurvey: response.data[i].field_total_survey[0].value,
    //             submissions: response.data[i].field_submissions[0].value,
    //             survey: response.data[i].field_survey[0].uri,
    //             choice: response.data[i].field_choices[0].uri,
    //             sync,
    //             bounds: [],
    //             udaLocation: [],
    //             udaPackages: [],
    //             udaProgressPie: [],
    //             udaProgressLatest: [],
    //             udaProgressTable: [],
    //             dsExcel: [],
    //             dsCsv: [],
    //             dsStata: [],
    //             dsSpss: [],
    //           })
    //         }
    //         // console.log(projectList)
    //         commit('setProjects', projectList)
    //         dispatch('projectSelected', [projectList[0]])

    //         // resolve(response);
    //       })
    //       .catch(error => {
    //         // console.log(error)
    //         reject(error)
    //       })
    //   })
    // },

    // // Calls action with package name and downloads data
    // udaPack ({ state, dispatch, commit }, selectedProject) {
    //   const packs = selectedProject[0].udaPackages
    //   // dispatch('setUdaPackStatus', ['udaLocation', 'noData'])
    //   // console.log(packs)
    //   for (var i = 0, len = packs.length; i < len; i++) {
    //     const pack = packs[i]
    //     if (selectedProject[0][pack].length > 0) {
    //       // console.log(pack)
    //       dispatch('setUdaPackStatus', [pack, 'ready'])
    //       // dispatch action e.g.  dispatch(udaLocation, [udaLocation])
    //       dispatch(pack, [pack])
    //       // commit("udaPackStatus", [pack, "ready"]);
    //       // } else if (selectedProject[0][pack].length === 0) {
    //       //  dispatch('setUdaPackStatus', [pack, 'noData'])
    //     } else {
    //       // dispatch action e.g.  dispatch(udaLocation, [udaLocation])
    //       dispatch(pack, [pack])
    //     }
    //   }
    // },

    // getPackageList ({ commit, state, dispatch }, projectNid) {
    //   // console.log('getPackageList da')
    //   // console.log("/api/packages/" + `${projectNid}`);
    //   return new Promise((resolve, reject) => {
    //     this._vm.$httpBend
    //       .get('/api/packages/' + `${projectNid}`, {
    //         headers: {
    //           Authorization: `Bearer ${state.auth.token}`,
    //         },
    //       })
    //       .then(response => {
    //         const packageList = []
    //         const projectPackage = []
    //         response.data.forEach(packages => {
    //           packageList.push({
    //             nid: packages.nid[0].value,
    //             name: packages.title[0].value,
    //             updated: packages.field_date[0].value,
    //           })
    //           projectPackage.push(packages.title[0].value)
    //         })
    //         state.projectList.find((project, i) => {
    //           if (project.nid === projectNid) {
    //             commit('setProjectListPackage', [projectPackage, i])
    //             return true // stop searching
    //           }
    //         })
    //         commit('setPackages', packageList)
    //         commit('setProjectPackage', projectPackage)
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         reject(error)
    //         // console.log(error)
    //       })
    //   })
    // },

    setUdaPackStatus ({ commit }, [packageName, status]) {
      // console.log('sending pack status update')
      // console.log(packageName)
      commit('udaPackStatus', [packageName, status])
    },

    // Write odkForm to  bend's odk text fields
    // Called from ODK Form page when run save action
    // OLD CODE Delete, i'm using below updateOdkForm()
    setOdkForm ({ commit, state, rootState }, props) {
      // console.log('setOdkForm da')
      const p = state.selectedProject[0]
      const id = p.id
      // console.log(props)
      const odkmodels = JSON.stringify(props[0])
      const odkquestions = JSON.stringify(props[1])
      const odklanguages = JSON.stringify(props[2])
      const odksettings = JSON.stringify(props[3])
      commit('saveOdkForm', props)
      // console.log(" here in syncData " + key);
      // console.log(date)
      const patchBody = {
        data: {
          type: 'node--projects',
          id: `${id}`,
          attributes: {
            field_odkmodels: `${odkmodels}`,
            field_odkquestions: `${odkquestions}`,
            field_odklangs: `${odklanguages}`,
            field_odksettings: `${odksettings}`,
          },
        },
      }
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .patch('/jsonapi/node/projects/' + id, patchBody, {
            headers: {
              Authorization: `Bearer ${rootState.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            // console.log('setOdkForm error')
            // console.log(error)
            reject(error)
          })
      })
    },

    // Write odkForm to  bend's odk text fields
    // Called from ODK Form page when run save action
    updateOdkForm ({ commit, rootState, state }, props) {
      const ocpuPack = rootState.ocpu.ocpuPack
      // STEP 1. Post new ODK Form
      const id = props.formjsonid
      const odk = {
        odkquestions: props.questions,
        odkmodels: props.models,
        odklangs: props.languages,
      }
      const patchBody = {
        data: {
          type: 'node--form',
          id: id,
          attributes: {
            field_form_settings: `${JSON.stringify(props.formsettings)}`,
            field_odk: `${JSON.stringify(odk)}`,
          },
        },
      }
      commit('updateOdkForm', props)
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${id}`,
            patchBody: patchBody,
            orgid: state.organization.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Write odkForm to  bend's odk text fields
    odkFormPhase ({ commit, rootState, state }, props) {
      const ocpuPack = rootState.ocpu.ocpuPack
      const id = props.formjsonid
      // STEP 1. Change setting's phase into "deployed".
      commit('updateOdkFormPhase', props)
      // console.log(JSON.stringify(state.organization.projects[props.ix.pindex].forms[props.ix.findex].odksettings))
      // console.log(props.formjsonid)

      // STEP 2. Post new ODK Form
      const patchBody = {
        data: {
          type: 'node--form',
          id: id,
          attributes: {
            field_form_settings: `${JSON.stringify(state.organization.projects[props.ix.pindex].forms[props.ix.findex].odksettings)}`,
            field_submissions: 0,
          },
        },
      }
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/form/${id}`,
            patchBody: patchBody,
            orgid: state.organization.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
            // return reject(error.message)
          })
      })
    },

    // // Write dataFilters to  bend's odk text fields
    // // Called from ProjectDetails page by clicking Save Filter
    // // OLD CODE
    // setDataFilter ({ commit, state, rootState }, props) {
    //   // console.log('setDataFilter da')
    //   const p = state.selectedProject[0]
    //   const id = p.id
    //   // console.log(props)
    //   const datafilter = JSON.stringify(props)

    //   commit('saveDataFilter', props)
    //   // console.log(" here in syncData " + key);
    //   // console.log(date)
    //   const patchBody = {
    //     data: {
    //       type: 'node--projects',
    //       id: `${id}`,
    //       attributes: {
    //         field_datafilter: `${datafilter}`,
    //       },
    //     },
    //   }
    //   return new Promise((resolve, reject) => {
    //     this._vm.$httpBend
    //       .patch('/jsonapi/node/projects/' + id, patchBody, {
    //         headers: {
    //           Authorization: `Bearer ${rootState.auth.token}`,
    //           'Content-Type': 'application/vnd.api+json',
    //           Accept: 'application/vnd.api+json',
    //         },
    //       })
    //       .then(response => {
    //         // console.log(response)
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         // console.log('setDataFilter error')
    //         // console.log(error)
    //         reject(error)
    //       })
    //   })
    // },

    /**
     * Bend PATCH and DELETE API calls via OCPU
     */

    patchNode ({ rootState }, props) {
      const ocpuPack = rootState.ocpu.ocpuPack
    // console.log(props)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: props.path,
            patchBody: props.patchBody,
            orgid: props.orgid,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
