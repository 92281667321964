// https://vuex.vuejs.org/en/actions.html
// https://www.drupal.org/docs/8/modules/jsonapi/jsonapi // documentations to Drupal JSON API

// TODO separate api calls into file. See Example of recommended way of usig ENDPOINTS:
// https://stackoverflow.com/questions/42560318/how-to-use-vue-resource-http-and-vue-router-route-in-a-vuex-store
// and https://github.com/vuejs/vuex/issues/85#issuecomment-224830681

// import axios from 'axios';
// import { stat } from 'fs'
// import { el } from 'date-fns/locale'
// import { addListener } from 'cluster'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {

  // Initial setup, prepares: onlineProjects, selectedProjects, argumentTaxonomies and
  // uda packages via runOcpuCode.
  async initialSetup ({ dispatch, state, commit }) {
    const runActions = actionList => {
      return dispatch(actionList)
    }

    const start = async () => {
      for (const actionList of [
        // 'bend/getOnlineProjects',
        'bend/argumentsGetTaxonomy',
        { type: 'ocpu/runOcpuCode', pkey: 0 },
      ]) {
        await runActions(actionList)
      }
      // set initialData to true to render Dashboard
      commit('setInitialData', true, { root: true })
      const formid = state.bend.selectedProject[0].formid
      // console.log(formid)
      // TODO check getTableListRules is commented!!!
      dispatch('ocpu/getTableListRules', formid)
      // console.log('Done, formid  ' + formid)
    }
    await start()
  },

  changeProject ({ dispatch, commit }) {
    dispatch('ocpu/runOcpuCode')
  },

  // // Run udaSave package, commit syncFolders
  // // should be deleted as I have genDataset
  // udacUpdate ({ commit, dispatch, state }, [projectKey, updateSelected]) {
  //   const ocpuPack = this.$store.state.ocpu.ocpuPack
  //   // console.log(' udaSave started')
  //   return new Promise((resolve, reject) => {
  //     // console.log(' sending to opcu udactable ')
  //     const p = state.projectList[projectKey]
  //     this._vm.$httpOcpu
  //       .post(`/${ocpuPack}/R/udactable/json`, {
  //         formid: p.formid,
  //         pid: p.pid,
  //       })
  //       .then(response => {
  //         /*
  //         // console.log(response)
  //         let data = response.data[0]
  //         let folder = data.split('/')
  //         let syncFolder = folder[3]
  //         commit('setProjectSyncFolder', [
  //           projectKey,
  //           syncFolder,
  //           updateSelected,
  //         ])
  //         let dataFiles = state.dataFiles
  //         let link = state.baseLinkOcpu + '/tmp/' + syncFolder
  //         for (var i = 0, len = dataFiles.length; i < len; i++) {
  //           let fileName = dataFiles[i]
  //           let uri = link + '/files/' + p.surveyId + '-' + fileName + '.zip'
  //           commit('setProjectSyncLink', [
  //             projectKey,
  //             fileName,
  //             uri,
  //             updateSelected,
  //           ])
  //         }
  //         */
  //         // console.log('udacUpdate resolved')
  //         if (response.status === 201) {
  //           resolve(response)
  //         }
  //       })
  //       .catch(error => {
  //         // console.log(error)
  //         reject(error)
  //       })
  //   })
  // },

  // // Get column names from server for selected table
  // getTableColumns ({ commit, state }, [projectKey, table, columns]) {
  //   const ocpuPack = this.$store.state.ocpu.ocpuPack
  //   return new Promise((resolve, reject) => {
  //     // console.log(columns)
  //     this._vm.$httpOcpu
  //       .post(`/${ocpuPack}/R/udacvars/json`, {
  //         tblname: table,
  //         columns: columns,
  //       })
  //       .then(response => {
  //         // console.log(response)
  //         if (response.data.length !== 0) {
  //           // console.log(response.data)
  //           // const data = Object.values(response.data)
  //           commit('setTableData', [projectKey, table, response.data, true])
  //         }
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         // console.log(error)
  //         reject(error)
  //       })
  //   })
  // },

  // Get column names from server for selected table check not using this??
  // for pivottable component I'm using following getVars action.
  // Check and delete this
  getTable1 ({ commit, state }, [projectKey, columns]) {
    const tableID = state.selectedTable[0].table[0]
    // console.log(tableID)

    return new Promise((resolve, reject) => {
      const p = state.projectList[projectKey]
      const data = columns
      this._vm.$httpOcpu
        .post(
          'https://ocpu.udaconsulting.com/ocpu/user/analytics/library/udaColumns/R/code/json',
          {
            tableID: tableID,
            survey: p.survey,
            choice: p.choice,
            nameCol: data,
          },
        )
        .then(response => {
          // console.log(response)
          if (response.data.length !== 0) {
            // console.log(response.data)
            // const data = Object.values(response.data)
            commit('setCrossTabData', [projectKey, response.data, true])
          }
          resolve(response)
        })
        .catch(error => {
          // console.log(error)
          reject(error)
        })
    })
  },

  // settings Get taxonomies
  settingGetTaxonomy ({ state, commit }, taxonomy) {
    // {server}/jsonapi/taxonomy_term/settings_name?fields[taxonomy_term--settings_name]=name,parent,field_boolean,field_icon_name,description
    return new Promise((resolve, reject) => {
      this._vm.$httpBend
        .get(
          '/jsonapi/taxonomy_term/' +
            taxonomy +
            '?fields[taxonomy_term--settings_name]=name,parent,field_boolean,field_icon_name,description',
          {
            headers: {
              Authorization: `Bearer ${state.auth.token}`,
            },
          },
        )
        .then(response => {
          // console.log(response)
          const myResponse = response.data.data
          const taxonomy = []
          for (var i = 0, len = myResponse.length; i < len; i++) {
            const obj = {}
            obj.name = myResponse[i].attributes.name
            obj.description = myResponse[i].attributes.description.value
            obj.id = myResponse[i].id
            obj.parent = myResponse[i].relationships.parent.data[0].id
            obj.single = myResponse[i].attributes.field_boolean
            obj.icon = myResponse[i].attributes.field_icon_name
            taxonomy.push(obj)
          }
          // console.log(taxonomy)
          commit('settingSetBlockFull', taxonomy)
          const obj = state.settingBlockFull
          const filteredTerms = obj.filter(x => x.parent === 'virtual')
          commit('settingSetBlockType', filteredTerms)
          resolve(response)
        })
        .catch(err => {
          // console.log('settingGetTaxonomy error')
          reject(err)
        })
    })
  },

  // settings Get Monitoring settings for selected project
  settingGetSettings ({ state, commit }) {
    // {server}/jsonapi/node/settings?filter[title]=42&include=field_settings
    const projectNid = state.bend.selectedProject[0].nid
    return new Promise((resolve, reject) => {
      this._vm.$httpBend
        .get(
          '/jsonapi/node/settings?filter[title]=' +
            projectNid +
            '&include=field_settings',
          {
            headers: {
              Authorization: `Bearer ${state.auth.token}`,
            },
          },
        )
        .then(response => {
          // console.log(response)
          const mainPart = response.data.data
          const settingsPart = response.data.included
          let incStart = 0
          let incLen = 0
          let incStop = 0
          const mSettings = []
          for (let k = 0, len = mainPart.length; k < len; k++) {
            const settingsItem = {}
            settingsItem.title = mainPart[k].attributes.body.value
            settingsItem.mBlock = mainPart[k].attributes.body.summary
            settingsItem.created = mainPart[k].attributes.created
            settingsItem.changed = mainPart[k].attributes.changed
            settingsItem.tableName = mainPart[k].attributes.field_table_name
            settingsItem.nid = mainPart[k].attributes.drupal_internal__nid
            settingsItem.id = mainPart[k].id

            const objSettings = []
            incLen = mainPart[k].relationships.field_settings.data.length
            incStop = incLen + incStop
            incStart = incStop - incLen
            for (let i = incStart; i < incStop; i++) {
              const obj = {}
              obj.title = settingsPart[i].attributes.title
              obj.value = settingsPart[i].attributes.field_settings_value
              obj.id = settingsPart[i].id
              obj.nid = settingsPart[i].attributes.drupal_internal__nid
              settingsItem[settingsPart[i].attributes.title] =
                settingsPart[i].attributes.field_settings_value
              obj[settingsPart[i].attributes.title] =
                settingsPart[i].attributes.field_settings_value
              objSettings.push(obj)
            }
            settingsItem.settings = Object.assign(
              [],
              settingsItem,
              objSettings,
            )
            mSettings.push(settingsItem)
          }
          commit('settingSetMonitoring', mSettings)
          // this.mSettings = mSettings;
          resolve(response)
        })
        .catch(error => {
          // console.log('settingGetSettings error')
          reject(error)
        })
    })
  },

  // Patch Bend Sync fileds with
  exportOdk (
    { dispatch, state, commit },
    [projectKey, projectNid, surveyId, updateSelected],
  ) {
    return dispatch('udaOdk', [projectKey, surveyId, updateSelected]).then(
      () => {
        const d = new Date()
        const syncDate = d.toISOString().slice(0, 19) + '+00:00'
        const postParams = {
          _links: {
            type: {
              href: `${state.auth.baseUrl}/rest/type/node/projects`,
            },
          },
          type: [{ target_id: 'projects' }],
          field_date: [{ value: syncDate }],
        }
        // console.log(postParams)
        return new Promise((resolve, reject) => {
          this._vm.$httpBend
            .patch('/node/' + projectNid + '?_format=json', postParams, {
              headers: {
                Authorization: `Bearer ${state.auth.token}`,
              },
            })
            .then(response => {
              commit('setProjectSyncDate', [
                projectKey,
                syncDate,
                updateSelected,
              ])
              resolve(response)
            })
            .catch(err => {
              // console.log('exportOdk error')
              reject(err)
            })
        })
      },
    )
  },
}
