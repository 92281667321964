import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    // // Landing page for UDACAPI
    // {
    //   path: '/',
    //   component: () => import('@/layouts/home/Index.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'Home',
    //       component: () => import('@/views/home/home/Index.vue'),
    //     },
    //     {
    //       path: 'about',
    //       name: 'About',
    //       component: () => import('@/views/home/about/Index.vue'),
    //       meta: { src: require('@/assets/about.jpg') },
    //     },
    //     {
    //       path: 'contact-us',
    //       name: 'Contact',
    //       component: () => import('@/views/home/contact-us/Index.vue'),
    //       meta: { src: require('@/assets/contact.jpg') },
    //     },
    //     {
    //       path: 'plans',
    //       name: 'Plans',
    //       component: () => import('@/views/home/plans/Index'),
    //       meta: { src: require('@/assets/plans.jpg') },
    //     },
    //     {
    //       path: 'login',
    //       name: 'Login',
    //       component: () => import('@/views/home/login/Index'),
    //       meta: { src: require('@/assets/sink.jpg') },
    //     },
    //     {
    //       name: 'Logout',
    //       path: 'logout',
    //       component: () => import('@/views/home/Logout'),
    //     },
    //   ],
    // },

    // landing page for client
    {
      path: '/',
      component: () => import('@/layouts/homeclient/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/homeclient/home/Index.vue'),
        },
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/homeclient/login/Index'),
          meta: { src: require('@/assets/sink.jpg') },
        },
        {
          name: 'Logout',
          path: 'logout',
          component: () => import('@/views/homeclient/Logout'),
        },
      ],
    },

    // Profile page
    {
      path: '/account',
      component: () => import('@/layouts/account/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: 'Active Forms',
          path: 'active',
          component: () => import('@/views/account/FormsActive'),
        },
        {
          name: 'Project List',
          path: 'projects',
          component: () => import('@/views/account/ProjectList'),
        },
        // {
        //   name: 'Monitoring Preferences',
        //   path: 'blocks',
        //   component: () => import('@/views/account/MonitoringPreferences'),
        //   props: true,
        // },
        // {
        //   name: 'Active Form Settings',
        //   path: 'fsa',
        //   component: () => import('@/views/account/FormSettingsActive'),
        //   props: true,
        // },
        {
          name: 'Project Settings',
          path: 'project',
          component: () => import('@/views/account/ProjectSettings'),
          props: true,
        },
        {
          name: 'Account Settings',
          path: 'settings',
          component: () => import('@/views/account/AccountSettings'),
        },
        // {
        //   name: 'Project Users',
        //   path: 'users',
        //   component: () => import('@/views/account/Users'),
        // },
        // {
        //   name: 'Form App Users',
        //   path: 'appusers',
        //   component: () => import('@/views/account/FormAppUsers'),
        // },
        // {
        //   name: 'Form Desing',
        //   path: 'design',
        //   component: () => import('@/views/odkform/OdkDesign'),
        //   props: true,
        // },
      ],
    },

    // Dashboard
    {
      path: '/dashboard',
      component: () => import('@/layouts/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // ===== Dashboard =====
        {
          name: 'Dashboard',
          path: '/dashboard/main',
          component: () => import('@/views/dashboard/Dashboard'),
          props: true,
        },

        // ===== Monitoring Tools =====
        // mTables
        {
          name: 'Monitoring Tables',
          path: 'monitor/mtables',
          component: () => import('@/views/dashboard/monitoring/MonitorTables'),
        },
        // // mCharts
        // {
        //   name: 'Monitoring Charts',
        //   path: 'monitor/mcharts',
        //   component: () => import('@/views/dashboard/monitoring/MonitorCharts'),
        // },
        // mStats
        {
          name: 'Monitoring Stats',
          path: 'monitor/mstats',
          component: () => import('@/views/dashboard/monitoring/MonitorStats'),
        },

        // ===== Mapboard =====
        {
          name: 'Mapboard',
          path: 'maps/mapboard',
          component: () => import('@/views/dashboard/maps/Mapboard'),
        },

        // // ===== Analysis =====
        // {
        //   name: 'Data Analysis',
        //   path: 'analysis/pivot',
        //   component: () => import('@/views/dashboard/analysis/PivotTable'),
        // },
        // {
        //   name: 'Descriptive Statistics',
        //   path: 'analysis/descriptive',
        //   component: () => import('@/views/dashboard/analysis/DescriptStat'),
        // },

        // ===== Data Processing =====
        {
          name: 'Quality Control',
          path: 'processing/check',
          component: () => import('@/views/dashboard/processing/QualityControl'),
        },

        // ===== Data Export =====
        {
          name: 'Data Export',
          path: 'export',
          component: () => import('@/views/dashboard/export/DataExport'),
        },

        // // ===== ODK Form Build =====
        // {
        //   name: 'Form build',
        //   path: 'odk/form',
        //   component: () => import('@/views/odkform/Form'),
        // },

        // ===== Settings =====
        {
          name: 'Settings',
          path: 'settings',
          component: () => import('@/views/dashboard/settings/FormSettings'),
        },
      ],
    },

    // 404 Error page
    {
      path: '*',
      component: () => import('@/layouts/404/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/Error'),
        },
      ],
    },

  ],
})

export default router

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['auth/authorized']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      const user = store.getters['auth/role']
      // console.log(user);
      if (to.matched.some(record => record.meta.udaStaff)) {
        if (user === 'UDA') {
          next()
        } else {
          next({ name: 'Dashboard' })
        }
      } else {
        next()
      }
    }
  } else {
    next() // make sure to always call next()!
  }
})
