/**
 * Authentification related vuex
 */

import axios from 'axios'
export default {
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */

  state: {
    baseUrl: 'https://bend.udacapi.org',
    client_uuid: 'fee3e601-a278-4b0f-adc8-ca7f41292155',
    // baseUrl: 'https://bend.udaconsulting.com',
    authStatus: '',
    token: '', // localStorage.getItem('token') || '',
    rtoken: '', // localStorage.getItem('rtoken') || '',
    user: {
      activated: true,
    },
    // clientId: '',
  },

  /* =================================================
  //                     GETTERS
  ================================================== */

  getters: {
    authorized: state => !!state.token,
    // udaStaff: state => state.user.udaStaff,
    authstatus: state => state.authStatus,
  },

  /* =================================================
  //                     MUTATIONS
  ================================================== */

  mutations: {
    reset (state) {
      // state.baseUrl = 'http://bend.udacapi.loc/web'
      state.baseUrl = 'https://bend.udacapi.org'
      state.client_uuid = 'fee3e601-a278-4b0f-adc8-ca7f41292155'
      state.authStatus = ''
      state.token = '' // localStorage.getItem('token') || '',
      state.rtoken = '' // localStorage.getItem('rtoken') || '',
      state.user = {}
      state.user.activated = true
    },
    // LOGIN and AUTHENTICATION Related
    auth_request (state) {
      state.authStatus = 'loading'
    },

    auth_success (state, [token, rtoken]) {
      state.authStatus = 'success'
      state.token = token
      state.rtoken = rtoken
    },

    auth_error (state) {
      state.authStatus = 'error'
    },

    logout (state) {
      // state.clientId = ''
      state.authStatus = ''
      state.token = ''
      state.rtoken = ''
      state.user = {}
      // state.user.avatar = ''
      // state.user.clientId = ''
      // state.user.udsStaff = false
      // state.user.username = ''
      // state.projectList = []
      // state.selectedProject = []
      // state.packageList = []
    },

    lockscreen (state) {
      state.authStatus = ''
      state.token = ''
      state.rtoken = ''
      state.clientId = ''
    },

    setClientId (state, clientId) {
      state.clientId = clientId
    },

    userProfile (state, profile) {
      state.user = Object.assign({}, state.user, profile)
    },
  },

  /* =================================================
  //                     ACTIONS
  ================================================== */

  actions: {

    // Reset state when loged out
    reset ({ commit }) {
      commit('reset')
    },

    /*
    // Check if user is uda staff
    checkuda ({ commit }, userData) {
      let clientId = ''
      let udaStaff = ''
      return new Promise((resolve, reject) => {
        // console.log('checkuda ' + userData.username)
        axios.get('https://bend.udacapi.org/api/uda/' + userData.username)
          .then(response => {
            // console.log(response.data)
            if (response.data.length !== 0) {
              clientId = '023dfe38-63d1-4771-99d7-2fd37b0319c3'
              udaStaff = true
            } else {
              clientId = state.client_uuid
              udaStaff = false
            }
            // console.log(clientId)
            localStorage.setItem('clientId', clientId) // FIXME change from saving in localstorage
            commit('userProfile', { clientId: clientId, udaStaff: udaStaff })
            commit('setClientId', clientId)
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },
    */

    login ({ dispatch, commit, state }) {
      // console.log(state.user.fd)
      return new Promise((resolve, reject) => {
        // commit('auth_request')
        // this._vm.$httpBend
        //   .post('/oauth/token', state.user.fd)
        axios.post(`${state.baseUrl}/oauth/token`, state.user.fd)
          .then(response => {
            // console.log(response.data)
            const token = response.data.access_token
            const rtoken = response.data.refresh_token
            // const user = userData.username
            commit('auth_success', [token, rtoken])
            // commit('userProfile', { username: user })
            // localStorage.setItem('token', token)
            // localStorage.setItem('rtoken', rtoken)
            // get info about current user
            dispatch('getUserDetails')
            // localStorage.setItem("user", user);
            // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    async getUserDetails ({ dispatch, commit, state }) {
      // Get valid token
      // await dispatch('getToken')
      // STEP 2. Call endpoint with uuid to get logged in user's details
      const getDetails = (uuid) => {
        // console.log(uuid)
        return new Promise((resolve, reject) => {
          this._vm.$httpBend
            .get(`/jsonapi/user/user/${uuid}`, {
              headers: {
                Authorization: `Bearer ${state.token}`,
                Accept: 'application/hal+json',
              },
            })
            .then(response => {
              // console.log(response.data.data)
              const obj = response.data.data.attributes
              // console.log(obj)
              const details = {}
              details.uuid = uuid
              details.uid = obj.drupal_internal__uid
              details.username = obj.name
              // details.odk_server = obj.field_odk_server.uri
              commit('userProfile', details)
              const img = response.data.data.relationships.field_image
              if (img.data !== null) {
                dispatch('getImage', img.links.related.href)
              } else {
                // details.awatar = ''
              }
              // FIXIT later delete this part. userd for old registered users
              const org = response.data.data.relationships.field_organization
              // console.log(org)
              if (org.data === null) {
                commit('userProfile', { userorg: false })
              } else {
                commit('userProfile', { userorg: true })
              }
              // console.log(details)
              return resolve(true)
            })
            .catch(error => {
              // console.log(error.message)
              return reject(error.message)
            })
        })
      }

      // STEP 1. Call endpoint to get user uuid
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .get('/jsonapi/', {
            headers: {
              Authorization: `Bearer ${state.token}`,
              Accept: 'application/hal+json',
            },
          })
          .then(response => {
            // console.log(response)
            getDetails(response.data.meta.links.me.meta.id)
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    getImage ({ commit, state }, uri) {
      // console.log('getImage')
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .get(uri, {
            headers: {
              Authorization: `Bearer ${state.token}`,
              Accept: 'application/hal+json',
            },
          })
          .then(response => {
            // console.log(response.data)
            const link = response.data.data.attributes.uri.url
            commit('userProfile', { avatar: `${state.baseUrl}${link}` })
            return resolve(true)
          })
          .catch(error => {
            // console.log(error.message)
            return reject(error.message)
          })
      })
    },

    // Post endpoint is not working
    resetpassword ({ state }, prop) {
      // const userPath = 'foydalanuvchi'
      const userPath = 'user' // original user path

      // console.log('reset')
      let data = {}
      let link = ''
      if (prop.type) {
        data = {
          name: prop.email,
          temp_pass: prop.code,
          new_pass: prop.newpass,
        }
        link = `${state.baseUrl}/${userPath}/lost-password-reset?_format=json`
      } else {
        data = {
          mail: prop.email,
        }
        link = `${state.baseUrl}/${userPath}/lost-password?_format=json`
      }
      // console.log(`${state.baseUrl}/${userPath}/lost-password?_format=json`)
      return new Promise((resolve, reject) => {
        axios.post(link, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => {
            // console.log(error.message)
            // console.log(error.response)
            reject(error.response)
          })
      })
    },

    logout ({ dispatch, commit }) {
      const modules = ['bend', 'main', 'ocpu']
      // console.log('logout')
      return new Promise((resolve, reject) => {
        localStorage.removeItem('token')
        localStorage.removeItem('rtoken')
        // localStorage.removeItem('clientId')
        localStorage.removeItem('user')
        // delete axios.defaults.headers.common['Authorization']
        commit('logout')
        modules.forEach(module => {
          dispatch(
            { type: `${module}/reset` },
            { root: true })
        })
        // dispatch(
        //   { type: 'main/reset' },
        //   { root: true })
        resolve()
      })
    },

    lockscreen ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('lockscreen')
        localStorage.removeItem('token')
        localStorage.removeItem('rtoken')
        // localStorage.removeItem('clientId')
        // delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },

    registerUser ({ state }, payload) {
      const data = {
        _links: {
          type: {
            href: `${state.baseUrl}/rest/type/user/user`,
          },
        },
        name: [{ value: payload.name }],
        mail: [{ value: payload.mail }],
        pass: [{ value: payload.pass }],
      }
      // console.log(data)
      return new Promise((resolve, reject) => {
        axios.post(`${state.baseUrl}/user/register?_format=hal_json`, data, {
        // this._vm.$httpBend
        //   .post('/user/register?_format=hal_json', data, {
            headers: {
              'Content-Type': 'application/hal+json',
              Accept: 'application/json',
            },
          })
            .then(response => {
              // console.log(response.status)
              resolve(response.status)
            })
            .catch(error => {
              // console.log(error.response.data.message)
              resolve(error.response.data.message)
              reject(error)
            })
      })
    },

    changeUserPass ({ state }, props) {
      const data = {
        _links: {
          type: {
            href: `${state.baseUrl}/rest/type/user/user`,
          },
        },
        pass: [{ existing: props.current, value: props.new }],
      }
      return new Promise((resolve, reject) => {
        this._vm.$httpBend
          .patch(`/user/${state.user.uid}?_format=json`, data, {
            headers: {
              Authorization: `Bearer ${state.token}`,
              Accept: 'application/json',
              'Content-Type': 'application/hal+json',
            },
          })
          .then(response => {
            // console.log(response)
            if (response.status === 200) {
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            return error
          })
      })
    },

    // Make sure that token is always valid
    async getToken ({ state, commit }) {
      // console.log('getToken ga keldi')

      // Step 2. If access token is not valid - use refresh token to refresh it
      const refreshToken = () => {
        // console.log('refreshToken ga keldi')
        const tokenData = new FormData()
        tokenData.set('grant_type', 'refresh_token')
        tokenData.set('client_id', state.client_uuid)
        tokenData.set('refresh_token', state.rtoken)

        return new Promise((resolve, reject) => {
          axios.post(`${state.baseUrl}/oauth/token`, tokenData)
            .then(response => {
              // console.log(response)
              const token = response.data.access_token
              const rtoken = response.data.refresh_token
              commit('auth_success', [token, rtoken])
              resolve(true)
            })
            .catch(error => {
              // console.log(error.message)
              return reject(error.message)
            })
        })
      }

      const config = {
        method: 'get',
        url: `${state.baseUrl}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
          Accept: 'application/hal+json',
        },
      }

      // axios request with try/catch
      try {
        // Step 1. First check if access token is still valid
        // console.log('First check if access token is still valid')
        await axios(config)
        // commit('userProfile', { activated: true })
      } catch (error) {
        // console.log('in case of error call refreshToken')
        // in case of error call refreshToken
        await refreshToken()
        // commit('userProfile', { activated: true })
        try {
          await axios(config)
        } catch (error) {
          commit('userProfile', { activated: false })
        }
      }
    },

    deleteUser ({ commit, state }) {
      // console.log('delUser')
      return new Promise((resolve, reject) => {
        axios.delete(`${state.baseUrl}/jsonapi/user/user/${state.user.uuid}`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
        })
        .then(response => {
          // console.log(response.status)
          commit('reset')
          resolve(response.status)
        })
        .catch(error => {
          // console.log(error)
          resolve(error)
          reject(error)
        })
      })
    },
  },
}
