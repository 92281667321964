import axios from 'axios'
import store from '../store'

export function getOcpuUrl () {
  return store.state.ocpu.ocpuUrl
}

export function getOcpuUser () {
  return store.state.ocpu.ocpuUser
}

const ocpuDomain = getOcpuUrl()
const ocpuUser = getOcpuUser()

const obaseURL = `${ocpuDomain}/user/${ocpuUser}/library`
// // Alternative: Using root library instead of user library
// const obaseURL = `${ocpuDomain}/library`

const ocpuInstance = axios.create({
    baseURL: obaseURL,
  })

export default ocpuInstance

// Vue.prototype.$ocpu = ocpuInstance
