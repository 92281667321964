/**
 * Main store for active states
 */

// import state from '../state'

// How to use $t from vue-i18n inside Vuex action
// https://forum.vuejs.org/t/how-to-use-t-from-vue-i18n-inside-vuex-action/22146/13

export default {
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */

  state: {
    ix: { pindex: null, findex: null },
    role: '',
    ap: '',
    af: '',
    afid: '',
    afsvers: {},
    // afsvers: {
    //   fromversion: [{ vstring: '' }],
    //   appversion: [''],
    //   state: [''],
    // },
    orgid: '',
    // delete below after a ew version
    mtoolList: [],

    mtools: {
      pivotTable: [],
      dStatistics: [],
    },
    qrCode: {
      details: {},
      original: {},
      updated: {},
    },
    dsb: {
      dBlocks: [
        { pack: 'ocprogpie', title: 'mpref.prog-pie', selected: false, tblname: [], question: false, variables: [] },
        { pack: 'ocprogtab', title: 'mpref.daily-preg', selected: false, tblname: [], question: false, variables: [] },
        {
          pack: 'oclocation',
          title: 'mpref.prog-map',
          selected: false,
          tblname: [],
          question: true,
          variables: [
            { title: 'mpref.lat', value: '', type: 'string' },
            { title: 'mpref.lon', value: '', type: 'string' },
          ],
        },
      ],
      fsettings: {
        surveyTotal: 100,
        syncMode: '600000',
        dBlocksInfo: true,
        dDatasetInfo: true,
        dmTableInfo: true,
        dmDstatInfo: true,
        dSettingsInfo: true,
      },
      dDataset: [
        // { pack: 'csv', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-table' },
        { pack: 'excel', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-microsoft-excel' },
        { pack: 'pexcel', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-microsoft-pexcel' },
        { pack: 'stata', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-database' },
        { pack: 'rds', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-language-r' },
        { pack: 'spss', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-database' },
        { pack: 'sas', selected: false, tblname: [], question: false, variables: [], readonly: false, icon: 'mdi-database' },
        {
          pack: 'arcgis',
          selected: false,
          tblname: [],
          attributes: [],
          question: true,
          variables: [
            { title: 'mpref.lat', value: '', type: 'string' },
            { title: 'mpref.lon', value: '', type: 'string' },
          ],
          readonly: false,
          icon: 'mdi-earth',
        },
      ],
      calls: false,
    },
  },

  /* =================================================
  //                     GETTERS
  ================================================== */

  getters: {
    // Get only selected dBolcks as array
    dBlockActive (state) {
      return state.dsb.dBlocks.filter(x => x.selected === true).map(x => x.pack)
    },
    // dBlockActive: state => state.fsettings.calls,

  },

  /* =================================================
  //                    MUTATIONS
  ================================================== */

  mutations: {
    // Reset to initial state
    reset (state) {
      state.ix.pindex = null
      state.ix.findex = null
      state.ap = ''
      state.af = ''
      state.afid = ''
      state.afsvers = null
      state.orgid = ''
      state.mtoolList = []
      state.qrCode.details = null
      state.qrCode.original = null
      state.qrCode.updated = null
      state.mtools.pivotTable = null
      state.mtools.dStatistics = null
    },

    // Update ix - indexes
    setIx (state, payload) {
      state.ix = payload
    },

    setActiveProject (state, payload) {
      // console.log(payload)
      state.ap = payload
    },

    setProjectServer (state, payload) {
      // console.log(payload)
      state.ap.odk_server = payload
    },

    setActiveForm (state, payload) {
      // console.log(payload)
      state.af = payload
    },

    setActiveFormId (state, payload) {
      // console.log(payload)
      state.afid = payload
    },

    setOrgid (state, payload) {
      state.orgid = payload
    },

    delOrgid (state) {
      delete state.orgid
    },

    setMtoolList (state, payload) {
      state.mtoolList = payload
    },

    setMtool (state, payload) {
      state.mtools[payload.type] = payload.data
    },

    setMtoolData (state, payload) {
      state.mtools.dStatistics[payload.i].option = payload.data
    },

    // Set dashboard Blocks according to ocpu
    setdBlocks (state, payload) {
      state.dsb.dBlocks = payload
    },

    setdBlockTable (state, payload) {
      state.dsb.dBlocks[payload.item].tblname = payload.tbl
    },

    setdBlockVars (state, payload) {
      for (let i = 0; i < payload.var.length; i++) {
        state.dsb.dBlocks[payload.item].variables[i].value = payload.var[i]
      }
    },

    // Change dashboard block selected state accordingly
    setdBlockState (state, payload) {
      // console.log(payload)
      const index = state.dsb.dBlocks.findIndex(x => x.pack === payload.pack)
      state.dsb.dBlocks[index] = payload
    },

    cancelDsbCalls (state) {
      // console.log('call cancel')
      state.dsb.calls = false
    },

    // Set dashboard Datasets according to ocpu
    setdDatasets (state, payload) {
      state.dsb.dDataset = payload
    },

    // Change dashboard Datasets selected state accordingly
    setdDatasetState (state, payload) {
      // console.log(payload)
      const index = state.dsb.dDataset.findIndex(x => x.pack === payload.pack)
      state.dsb.dDataset[index].selected = payload.state
    },

    // QR Code related muations
    setQcDetails (state, payload) {
      state.qrCode.details = payload
    },
    updateQcDetails (state, payload) {
      // qrCode.details.general[i].items[pos].selected = selected[0]
      state.qrCode.details.general[payload.i].items[payload.pos].selected = payload.selected
      if (payload.type === 'checkbox') {
        state.qrCode.details.general[payload.i].items[payload.pos].options[0].value = payload.selected.value
        state.qrCode.details.general[payload.i].items[payload.pos].forchange = payload.forchange
      }
    },
    setQcOriginal (state, payload) {
      state.qrCode.original = payload
    },
    setQcUpdated (state, payload) {
      state.qrCode.updated = Object.assign({}, state.qrCode.updated, payload)
      // console.log(state.qrCode.updated)
    },

    // Set form of Selected Project
    setProjectRoles (state, payload) {
      // Called from delete-projectrole.js
      state.ap.roles = payload.roles
    },

    // Set form's Server version
    setfSVersion (state, payload) {
      state.afsvers = payload
    },

    // Form settings related mutations
    setfSetting (state, payload) {
      state.dsb.fsettings[payload.el] = payload.val
    },

    // Update full settings
    setfSettingFull (state, payload) {
      state.dsb.fsettings = payload
    },

  },

  /* =================================================
  //                    ACTIONS
  ================================================== */

  actions: {

    // Reset state when loged out
    reset ({ commit }) {
      commit('reset')
    },

    // Set All Indexes: ix, Active Project and Active Form; set dsb call to false
    setIndexes ({ commit, rootState }, payload) {
      // console.log(payload)
      // console.log(payload.pindex)
      commit('setIx', payload)
      // active project always exists in payload
      const ap = rootState.bend.organization.projects[payload.pindex]
      const orgid = rootState.bend.organization.orgid
      // console.log(ap.prjid)
      // console.log(orgid)
      commit('setActiveProject', ap)
      const getserver = () => {
        const ocpuPack = rootState.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this._vm.$httpOcpu
            .post(`/${ocpuPack}/R/getpinfo/json`, {
              orgid: orgid,
              prjid: ap.prjid,
            })
            .then(response => {
              // console.log(response.data.server[0])
              commit('setProjectServer', response.data.server[0])
              resolve(response)
              // if (response.status === 201) {
              //   resolve(response.data.server[0])
              // }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      }
      getserver()

      if (payload.findex !== null) {
        // console.log(payload.findex)
        const af = ap.forms[payload.findex]
        commit('setActiveForm', af)
        const afid = af.odksettings.id
        commit('setActiveFormId', afid)
        commit('cancelDsbCalls')
      }
    },

    // // Set Indexes and Active Project
    // setPIndex ({ commit, rootState }, payload) {
    //   // console.log(payload)
    //   commit('setIx', payload)
    //   const ap = rootState.bend.organization.projects[payload.pindex]
    //   commit('setActiveProject', ap)
    // },
  },

}
