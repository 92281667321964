/**
 * Store for ODK Form relates states
 */

// import findPath from '@/views/odkform/components/findPath'
export default {
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */

  state: {
    //
    models: [],
    questions: [],
    languages: [
      { code: 'en', label: 'English', default: true, done: '' },
    ],
    settings: { form_title: '', form_id: '', version: '', public_key: '' },
  },

  /* =================================================
  //                    MUTATIONS
  ================================================== */

  mutations: {
    // Get and update odk module and questions from selected project
    // Called from ODK Form builder (Form.vue), Same for save is in bend mutations
    getOdkForm: (state, payload) => {
      state.models = payload.models
      state.questions = payload.questions
      state.languages = payload.langs
      state.settings = payload.settings
    },

    addModel: (state, payload) => {
      // console.log(payload)
      state.models.push(payload)
    },

    removeModel: (state, payload) => {
      // get index of Model object https://stackoverflow.com/a/15998003
      const index = state.models.findIndex(m => m.internalKey === payload)
      // remove object of Element
      state.models.splice(index, 1)
    },

    updateModel: (state, payload) => {
      // get index of Model object https://stackoverflow.com/a/15998003
      const index = state.models.findIndex(m => m.internalKey === payload[0])
      // remove object of Element
      state.models.splice(index, 1)
      // insert updated Model object
      state.models.push(payload[1])
    },

    updateSettings: (state, payload) => {
      // { title: '', name: '', version: '', key: '' }
      state.settings = payload
    },

    // updateModelName: (state, payload) => {
    // // console.log(payload)
    //   state.questions[payload[0]].odk_name = payload[1]
    // },

    // updateModelNameGroup: (state, payload) => {
    // // console.log(payload)
    //   state.questions[payload[0]].columns[payload[1]].odk_name = payload[2]
    // },

    // Update validation of the model
    modelValidation: (state, payload) => {
      // traverse function to mutate model's validation
      function runTraverse (target) {
        // loop target questions
        target.forEach(function (arrayItem) {
          // if it has the same internalKey mutate it's [valid] property
          if (arrayItem.internalKey === payload[0]) {
            arrayItem.valid = payload[1]
          }
          // if it has children property it's a group: run the function again
          if ('children' in arrayItem) {
            runTraverse(arrayItem.children)
          }
        })
      }
      // run traverse function
      runTraverse(state.questions)
    },

    removeAllModels: state => {
      state.models = []
    },

    updateForm: (state, payload) => {
      // state.questions = []
      state.questions = payload
      // Vue.set(state, 'questions', payload)
    },

    defaultLanguage: (state, payload) => {
      // Traverse function to change all occurance of field from target
      function updateTraversal (target, field) {
        if (typeof target === 'object') {
          for (const key in target) {
            if (key === field) {
              const rfield = target[field].splice(index, 1)
              target[field].splice(0, 0, rfield[0])
            }
            updateTraversal(target[key], field)
          }
        }
        // return modified target
        return target
      }

      // payload[0] = lang.code; payload[1] = [transFields]
      const transField = payload[1]
      // Get the index of language object inside an array, matching a condition
      const index = state.languages.findIndex(x => x.code === payload[0])
      // Cut the the language (choosed to be default)
      const removed = state.languages.splice(index, 1)
      // Paste that language into the top [0]
      state.languages.splice(0, 0, removed[0])
      // Change all language into default = false
      state.languages.map(l => { l.default = false })
      // Change the top language as default
      state.languages[0].default = true
      // Do the same with all translation fields in each model
      for (let i = 0; i < state.models.length; i++) {
        for (let j = 0; j < transField.length; j++) {
          const field = transField[j]
          // traversal modify all occurence
          updateTraversal(state.models[i], field)
        }
      }
    },

    // Mutate state.languages by pushing new lang and
    // Mutate state.models with new translation fields
    addLanguage: (state, payload) => {
      // console.log(payload)
      // Traverse function to add language to all occurance of translation fields
      function updateTraversal (target, field) {
        if (typeof target === 'object') {
          for (const key in target) {
            if (key === field) {
              // console.log(target[field])
              // console.log(field)
              target[field].push('')
            }
            updateTraversal(target[key], field)
          }
        }
        // return modified target
        return target
      }

      state.languages.push(payload[0])
      const transField = payload[1]
      for (let i = 0; i < state.models.length; i++) {
        for (let j = 0; j < transField.length; j++) {
          const field = transField[j]
          updateTraversal(state.models[i], field)
        }
      }
    },

    deleteLanguage: (state, payload) => {
      // Traverse function to delete language from all occurance of translation fields
      function updateTraversal (target, field) {
        if (typeof target === 'object') {
          for (const key in target) {
            if (key === field) {
              target[field].splice(index, 1)
            }
            updateTraversal(target[key], field)
          }
        }
        // return modified target
        return target
      }

      const index = payload[0]
      const transField = payload[1]
      state.languages.splice(index, 1)
      for (let i = 0; i < state.models.length; i++) {
        for (let j = 0; j < transField.length; j++) {
          const field = transField[j]
          updateTraversal(state.models[i], field)
        }
      }
    },

  },

  /* =================================================
  //                    ACTIONS
  ================================================== */

  actions: {

    addModel: ({ commit }, payload) => {
      // console.log(payload)
      commit('addModel', payload)
    },

    removeModel: ({ commit }, payload) => {
      commit('removeModel', payload)
    },

    updateModel: ({ commit }, payload) => {
      commit('updateModel', payload)
    },

    // // TODO move commit actions here
    // updateModelName: ({ commit, state }, payload) => {
    //   const q = state.questions
    //   // get index of Question object https://stackoverflow.com/a/15998003
    //   const index = q.findIndex(m => m.internalKey === payload[0])
    //   // update name in state.questions
    //   if (index !== -1) {
    //  // console.log(index)
    //     commit('updateModelName', [index, payload[1]])
    //   } else {
    //     // the model should be inside one of the groups. Loop all questions
    //     for (let i = 0; i < q.length; i++) {
    //       // if column lenght is more than 0 it's a group
    //       if (q[i].columns.length > 0) {
    //         // loop group models one by one
    //         for (let j = 0; j < q[i].columns.length; j++) {
    //           const cindex = q[i].columns.findIndex(m => m.internalKey === payload[0])
    //           // if cindex returns other than -1, we found model...
    //           if (cindex !== -1) {
    //             // update name in state.questions
    //             commit('updateModelNameGroup', [i, cindex, payload[1]])
    //             // break out of the loop
    //             break
    //           }
    //         }
    //       }
    //     }
    //   }
    // },

    // Model validation action copy validation state from model into questions
    modelValidation: ({ commit, state }, payload) => {
      let groupInvalids = 0
      let isGroup = false
      let valid = false
      // traverse function if question is a group
      function runTraverseGroup (target) {
        // loop target model
        target.forEach(function (arrayItem) {
          if (arrayItem.valid === false) {
            // count all invalid models in the group
            groupInvalids = groupInvalids + 1
          }
          if ('children' in arrayItem) {
            runTraverseGroup(arrayItem.children)
          }
        })
      }

      // group validation should based on each model in this group
      // traverse function model for groups
      function runTraverse (target) {
        // loop target model
        target.forEach(function (arrayItem) {
          // if it has the same internalKey mutate it's valid property
          if (arrayItem.internalKey === payload[0] & 'children' in arrayItem) {
            isGroup = true
            // run function for all children if it has any nested
            if ('children' in arrayItem) {
              runTraverseGroup(arrayItem.children)
            }
          }
          if ('children' in arrayItem) {
            runTraverse(arrayItem.children)
          }
        })
      }

      // run traverse function
      runTraverse(state.questions)

      // prepare valid property to commet mutation
      if (isGroup) {
        valid = groupInvalids === 0
      } else {
        valid = payload[1]
      }
      // validation of single model
      commit('modelValidation', [payload[0], valid])
    },

    removeAllModels: ({ commit }) => {
      commit('removeAllModels')
    },

    updateForm: ({ commit }, payload) => {
      commit('updateForm', payload)
    },

    defaultLanguage: ({ commit }, payload) => {
      commit('defaultLanguage', payload)
    },

    // Prepare for adding new language for state.languages and
    // translation fields for new language
    addLanguage: ({ state, commit }, payload) => {
      const l = payload[0]
      const fields = payload[1]
      const lang = { code: l.code, label: l.label, default: false, done: 0 }
      commit('addLanguage', [lang, fields])
    },

    // Prepare for delete selected language from state.languages and
    // translation fields for selected language
    // Called from Form
    deleteLanguage: ({ state, commit }, payload) => {
      // Find index of specific language using findIndex method.
      const langIndex = state.languages.findIndex(
        lang => lang.code === payload[0],
      )
      if (langIndex !== -1) commit('deleteLanguage', [langIndex, payload[1]])
   // console.log(langIndex)
    },

  },
}
