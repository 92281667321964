/**
 * Store for OCPU relates states
 */

 export default {
  namespaced: true,

  /* =================================================
  //                      STATE
  ================================================== */
  state: {
    tableListRules: [],
    defServer: [],
    oldServer: [],

    // // UDACAPI OpenCPU endpoints
    // ocpuUrl: 'https://ocpu.udacapi.com/ocpu',
    // ocpuUser: 'analyst',
    // ocpuPack: 'udaOcpu',

    // Client OpenCPU endpoints
    ocpuUrl: 'https://ocpu.survey.stat.gov.ct.tr/ocpu',
    ocpuUser: 'kamunet',
    ocpuPack: 'udaOcpu',
  },

  /* =================================================
  //                    MUTATIONS
  ================================================== */
  mutations: {
    // Reset to initial state
    reset (state) {
      state.tableListRules = []
      state.defServer = []
      state.oldServer = []

      // // UDACAPI OpenCPU endpoints
      // state.ocpuUrl = 'https://ocpu.udacapi.com/ocpu'
      // state.ocpuUser = 'analyst'
      // state.ocpuPack = 'udaOcpu'

      // Client OpenCPU endpoints
      state.ocpuUrl = 'https://ocpu.survey.stat.gov.ct.tr/ocpu'
      state.ocpuUser = 'kamunet'
      state.ocpuPack = 'udaOcpu'
    },

    // mutates tableListRules
    setTableListRules (state, payload) {
      // console.log(payload)
      state.tableListRules = payload
    },

    // mutates tableListRules
    setDefServer (state, payload) {
      // console.log(payload)
      state.defServer = payload.active[0]
      state.oldServer = payload.old
    },
  },

  getters: {},

  /* =================================================
  //                     ACTIONS
  ================================================== */
  actions: {
    // Reset state when loged out
    reset ({ commit }) {
      commit('reset')
    },

    // Sends request to ocpu about all tables and their variables
    // called from: 1) MonitoringBlocks
    ocTableListRules ({ commit, state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      // console.log('here in ocTableListRules ')
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocquestionlist/json`, {
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
            lang: payload.lang,
          })
          .then(response => {
            // console.log(response.data)
            if (response.data.length !== 0) {
              const ruleList = JSON.parse(JSON.stringify(response.data))
              for (let i = 0, len = ruleList.length; i < len; i++) {
                if (ruleList[i].column[0] !== 0) {
                  ruleList[i].column.map(function (part) {
                    // console.log(part)
                    part.id = part.id[0]
                    part.label = part.label[0]
                    if (part.type === undefined) {
                      // console.log('undef')
                      part.type = 'text'
                    } else {
                      part.type = part.type[0]
                    }
                    if ('choices' in part) {
                      for (let j = 0, len = part.choices.length; j < len; j++) {
                        part.choices[j].label = part.choices[j].label[0]
                        part.choices[j].value = part.choices[j].value[0]
                      }
                    }
                    if ('inputType' in part) {
                      part.inputType = part.inputType[0]
                    }
                    // if ('operator' in part) {
                    //   // console.log(part.operator)
                    //   // for (let j = 0, len = part.choices.length; j < len; j++) {
                    //   //   part.choices[j].label = part.choices[j].label[0]
                    //   //   part.choices[j].value = part.choices[j].value[0]
                    //   // }
                    //   part.operator = part.operator[0]
                    // }
                    // console.log(part)
                  })
                }
              }
              commit('setTableListRules', ruleList)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Sends request to getPackage action for all packages
    // called with async key from: 1) initialSetup; 2) bend/projectSelected
    async runOcpuCode ({ rootState, dispatch }, payload) {
      // console.log('here in runOcpuCode ')
      const packs = rootState.bend.onlineProjects[payload.pkey].packs
      for (const [pack] of Object.entries(packs)) {
        // console.log(pack)
        await dispatch({ type: 'getPackage', pack: pack, pkey: payload.pkey })
      }
      // When both key and value are needed simultaneously:
      /* for (let [key, value] of Object.entries(packs)) {
          // console.log(key, value);
      } */
    },

    // Runs ocpu functions for dataAccessPackages
    getPackage ({ commit, state, rootState }, payload) {
      const ocpuPack = state.ocpuPack
      const p = rootState.bend.onlineProjects[payload.pkey]
      const a = rootState.bend.packArguments[payload.pack].arguments
      // console.log(a)
      let args = {}
      for (let j = 0, len = a.length; j < len; j++) {
        const argname = a[j]
        const argval = p[argname]
        const temp = {}
        temp[argname] = argval
        args = Object.assign({}, args, temp)
      }
      // console.log(args)
      const daPacks = state.dataAccessPacks
      const validPacks = daPacks.includes(payload.pack)
      if (validPacks) {
        return new Promise((resolve, reject) => {
          this._vm.$httpOcpu
            .post(`/${ocpuPack}/R/${payload.pack}/json`, args)
            .then(response => {
              const data = response.data
              // console.log(data)
              if (data.length !== 0) {
                commit(
                  'bend/setPackageData',
                  { pack: payload.pack, value: data, pkey: payload.pkey },
                  { root: true },
                )
              }
              resolve(response)
            })
            .catch(error => {
              // dispatch('setUdaPackStatus', [payload, 'noData'])
              // console.log(error)
              reject(error)
            })
        })
      }
    },

    // Generate datasets for selected type from existing .Rda file
    genDataset ({ commit, dispatch, rootState, state }, dsname) {
      const ocpuPack = state.ocpuPack
      const pack = 'udac' + dsname
      const sp = rootState.bend.selectedProject[0]
      // console.log(sp)
      const id = sp.packs[pack].id
      return new Promise((resolve, reject) => {
        // console.log(' sending to zipfiles')
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/zipfiles/json`, {
            formid: sp.formid,
            filetype: dsname,
          })
          .then(response => {
            // console.log(response.data[0])
            const syncCode = response.headers['x-ocpu-session']
            const link =
              state.ocpuUrl +
              '/tmp/' +
              syncCode +
              '/files/' +
              response.data[0]
            dispatch('bend/setPackSyncData', [id, link, dsname], {
              root: true,
            })
            // console.log(' genDataset resolved')
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Updated code
    // Get number of submissions (using async to return promise)
    async getSubmissions ({ commit, state, dispatch, rootState }, prop) {
      const ocpuPack = state.ocpuPack
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/sbminserver/json`, prop)
          .then(response => {
            // console.log(response)
            resolve(response.data[0])
          })
          .catch(error => {
            // console.log('getSubmissions udacsubmissions error')
            reject(error)
          })
      })
    },

    // Runs odatasbm function to download and save latest submission tables
    // Called from 1) Dashboard - getSubmissions() 2) DataFilters - applyFilter()
    // 3 Active forms - getSubmissions()
    async odataSubmissions ({ commit, dispatch, rootState, state }, prop) {
      const props = {
        orgid: prop.orgid,
        prjid: prop.prjid,
        formid: prop.formid,
      }
      const ocpuPack = state.ocpuPack
      // console.log(' udacUpdate started')
      return new Promise((resolve, reject) => {
        // const p = rootState.bend.onlineProjects[key]
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/odatasbm/json`, props)
          .then(response => {
            // console.log(response)
            const d = new Date()
            const syncDate = d.toISOString().slice(0, 19) + '+00:00'
            // console.log(syncDate)
            dispatch(
              'bend/setFormSyncData',
              [prop.projectid, prop.ix, syncDate, prop.sserver],
              {
                root: true,
              },
            )
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get column names from server for selected table
    // CHECK THis is maybe not used anymore
    // Called from: 1) PivotTable
    getOcvars ({ rootState, state }, props) {
      const ocpuPack = state.ocpuPack
      console.log(JSON.stringify(props.columns))
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocvariables/json`, {
            orgid: props.orgid,
            prjid: props.prjid,
            formid: props.formid,
            tblname: props.tblname,
            columns: JSON.stringify(props.columns),
          })
          .then(response => {
            // if (response.data.length !== 0) {
            // }
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get column names from server for selected table
    // Called from: 1) PivotTable
    getVars ({ rootState, state }, columns) {
      const ocpuPack = state.ocpuPack
      const tableID = rootState.selectedTable[0].table[0]
      // console.log(tableID)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/udacvars/json`, {
            tblname: tableID,
            columns: columns,
          })
          .then(response => {
            if (response.data.length !== 0) {
            }
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get data for Dashboard map from server for selected table
    // Called from: 1) PivotTable
    async getLfltQuestion ({ state }, props) {
      const ocpuPack = state.ocpuPack
      // console.log(tableID)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/oclfltquestion/json`, {
            orgid: props.orgid,
            prjid: props.prjid,
            formid: props.formid,
          })
          .then(response => {
            // console.log(response)
            if (response.data.length !== 0) {
            }
            resolve(response.data)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get data for Dashboard map from server for selected table
    // Called from: 1)
    async getLfltData ({ state }, props) {
      const ocpuPack = state.ocpuPack
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/oclfltdata/json`, {
            orgid: props.orgid,
            prjid: props.prjid,
            formid: props.formid,
          })
          .then(response => {
            const mydata = response.data
            const len = mydata.length
            if (len !== 0) {
              const mydata = response.data.map((data, i) => ({
                ...data,
                // construct position array from coordinates
                position: {
                  lat: data.latitude,
                  lng: data.longitude,
                },
                // add visible key with 'true' value
                v: true,

              }))
              // TODO I could delete the Latitide and Longitude data
              resolve(mydata)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get descriptive statistics from server for variables of selected table
    // Called from: 1) MonitorStats (Descriptive Statistics)
    async getDstat ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(tblname + ' ' + qvar)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocdstat/json`, {
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
            tblname: payload.tblname,
            qvar: payload.qvar,
          })
          .then(response => {
            if (response.data.length !== 0) {
            }
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Build ODK Form
    async buldForm ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/udacform/json`, {
            formdetails: payload.formdetails,
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
          })
          .then(response => {
            if (response.data.length !== 0) {
              // console.log(response)
            }
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Build ODK Form
    async deployForm ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload.formdetails)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocfdeploy/json`, {
            server: payload.server,
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
          })
          .then(response => {
            // if (response.data.length !== 0) {
            //   // console.log(response)
            // }
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    async saveForm ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload.formdetails)
      // console.log(payload.formid)
      // Uncomment below when it's ready
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/savefdetails/json`, {
            formdetails: payload.formdetails,
            formid: payload.formid,
            formtype: payload.formtype,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    async deleteForm ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload.formsettings)
      // console.log(payload.formid)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/deletefdetails/json`, {
            formsettings: payload.formsettings,
            formid: payload.formid,
            formtype: payload.formtype,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get ODK Forms from ocpu
    // === New OdkDesigng is not useing this can be deleted later
    async getForm ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      // console.log('getQuery')
      // Uncomment below when it's ready
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/getformlist/json`, {
            formid: payload.formid,
            formtype: payload.formtype,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // console.log(response)
              resolve(response)
            }
            // resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Save filters to ocpu
    async saveQuery ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      // console.log(this._vm.$httpOcpu)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocsavequeries/json`, {
            queries: payload.queries,
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
          })
          .then(response => {
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Apply all queries
    async applyQueries ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocapplyqueries/json`, {
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Get filters to ocpu
    // TODO Check not used in DataCheck.vue
    async getQuery ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      // console.log('getQuery')
      // Uncomment below when it's ready
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocgetqueries/json`, {
            formid: payload.formid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              // console.log(response)
              resolve(response)
            }
            // resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    async downloadQuery ({ rootState, state }, payload) {
      const ocpuPack = state.ocpuPack
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/oczipquery/json`, {
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
            fileid: payload.fileid,
          })
          .then(response => {
            // console.log(response.data[0])
            const syncCode = response.headers['x-ocpu-session']
            const link =
              state.ocpuUrl +
              '/tmp/' +
              syncCode +
              '/files/' +
              response.data[0]
            resolve(link)
            // resolve(response)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    ocpostproject ({ commit, state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocpostproject/json`, {
            pname: payload.pname,
            server: payload.server,
            orgid: payload.orgid,
            prjid: payload.prjid,
            ownserver: payload.ownserver,
            user: payload.user,
            pass: payload.pass,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    appUser ({ commit, state }, payload) {
      const ocpuPack = state.ocpuPack
      // ocappuser() used for get and post app user info also to delete
      // console.log(payload)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocappuser/json`, {
            // server: payload.server,
            orgid: payload.orgid,
            prjid: payload.prjid,
            name: payload.name,
            id: payload.id,
            action: payload.action,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    webLink ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // ocpublink() used for get, post and delete Public Links
      // console.log(payload)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocpublink/json`, {
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
            name: payload.name,
            once: payload.once,
            id: payload.id,
            urloption: payload.urloption,
            action: payload.action,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    formVersions ({ state }, payload) {
      const ocpuPack = state.ocpuPack
      // console.log(payload)
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/getdformversions/json`, payload)
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    appUserActive ({ commit, state }, payload) {
      const ocpuPack = state.ocpuPack
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/ocformappuserlist/json`, {
            orgid: payload.orgid,
            prjid: payload.prjid,
            formid: payload.formid,
            roleid: 'app-user',
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    getDefServer ({ commit, state }) {
      const ocpuPack = state.ocpuPack
      // console.log('payload')
      return new Promise((resolve, reject) => {
        this._vm.$httpOcpu
          .post(`/${ocpuPack}/R/getdefserver/json`)
          .then(response => {
            // console.log(response)
            if (response.data.message[0] === 'Success') {
              commit('setDefServer', response.data)
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

  },
}
